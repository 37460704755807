<template>
  <div id="app">
    <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                    :curr-page.sync="pagingParams.currPage"
                    :per-page.sync="pagingParams.perPage"
                    :per-page-list="perPageList"
                    :pages="pages"
                    :search-bar-place-holder="searchBarPlaceHolder"
                    :keyword.sync="pagingParams.keyword">
      <template #tools>
        <CButton color="info" size="sm" class="m-1" @click="batchRemove">全体解除</CButton>

        <CButton color="info" size="sm" class="m-1" @click="removeSpec" :disabled="!selected">指定解除</CButton>
      </template>

    </MyBeeSearchBar>
    <BeeGridTable
        ref="bee"
        border
        stripe
        :showFilter="false"
        :columns="columns"
        :loading="isLoading"
        :data="rows"
        :showPager="false"
        :maxHeight="gridCardHeight"
        highlightRow
        no-data-text="データがありません。"
        no-filtered-data-text=""
        :context="context"
        :span-method="customSpan"
        :showVerticalScrollBar="false"
        :showHorizontalScrollBar="false"
        @on-selection-change="selectionChanged">
      <!-- HEADER ソート可能にするために 必要-->
      <template slot-scope="{ column,index }" slot="deviceIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="serialNumberHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="specFirmwareIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="gwNowFirmwareIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="firmwareVersionHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="memoHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <!-- cell -->
    </BeeGridTable>

  </div>
</template>

<script>


import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  name: "FirmwareSpecRemove",
  mixins: [BeeMix],
  components: {
    MyBeeHeader, MyBeeSearchBar
  },
  props:{
    isShow:{type:Boolean,default:false}
  },
  data() {
    return {
      searchBarPlaceHolder:"シリアル番号、指定ファームウェアIDで検索",
      columns: [
        {type: "selection", width: 60, maxWidth: 50,   align: "center", },
        // {title: 'GWシリアル番号', key: 'deviceId',headSlot: 'serialNumberHead'},
        {title: 'GWID', key: 'deviceId',headSlot: 'deviceIdHead'},
        {title: 'GWシリアル番号', key: 'serialNumber',headSlot: 'serialNumberHead'},
        {title: '指定ファームウェアID', key: 'specFirmwareId',headSlot: 'specFirmwareIdHead'},
        {title: '現在ファームウェアID', key: 'gwNowFirmwareId',headSlot: 'gwNowFirmwareIdHead'},
        {title: 'ファームウェアバージョン', key: 'firmwareVersion',headSlot: 'firmwareVersionHead',},
        {title: 'ファームウェアメモ', key: 'memo',headSlot: 'memoHead'},
        // {title: '', key: 'after',slot:"after"},
      ],
      selected:false,
    };
  },methods:{
    selectionChanged:function(){
      this.getCheckedList();
      this.selected = this.checkedList.length > 0;
    },
    batchRemove:function(){

      this.$swal({title:"確認",text: `全体解除処理を行いますか？`,buttons:true,dangerMode:true}).then((result) => {
        if (result) {
          this.isLoading = true;
          this.axios.post("/api/gateway/removeAllSpecFirmware",
              {}
              , {
                headers: {"Content-Type": "application/json"}
              }
          ).then(response => {
            console.log('response', response);
            this.goodPaging();
          }).catch(error => {
            console.log('error', error);
            console.log('error', error.response.data);
          }).finally(() => {
            console.log('finally');
            this.isLoading = false;
            this.showGateway = false;
          });
        }
      });



    },removeSpec:function(){
      let checkedDeviceIdList = this.checkedList.map(function(item){
        return item.deviceId;
      });
      this.isLoading = true;
      this.axios.post("/api/gateway/removeSpecFirmware",
          {gatewayIds:checkedDeviceIdList}
          , {
            headers: {"Content-Type": "application/json"}
          }
      ).then(response => {
        console.log('response', response);
        this.goodPaging();
      }).catch(error => {
        console.log('error', error);
        console.log('error', error.response.data);
      }).finally(() => {
        console.log('finally');
        this.isLoading = false;
        this.showGateway = false;
      });
    },
    goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      this.axios.post('/api/gatewayFirmwareSpecPaging', this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }
  },
  watch:{
    isShow:function(){
      if(this.isShow){
        this.goodPaging();
      }else{
        this.pagingParams.keyword = null;
        this.rows = [];
      }
    }
  }
};
</script>